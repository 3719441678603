import React, { FC } from 'react';
import { EventTrackerContext, Tracker } from './eventTracker';

export type Props = {
	trackers: Tracker[];
};

export const EventTrackerProvider: FC<Props> = ({ trackers, children }) => {
	return <EventTrackerContext.Provider value={trackers}>{children}</EventTrackerContext.Provider>;
};
