
type TrackPageViewArgs = { url: string; title: string };

export const createGtmTracker = (layer: string = 'dataLayer') => {
	const trackData = (data: { [key: string]: any }) => {
		// @ts-ignore
		const windowLayer = (window[layer] = window[layer] || []);
		windowLayer.push({ ...data });
	};

	return {
		trackPageView: ({ url, title }: TrackPageViewArgs) => {
			trackData({
				event: 'pageview',
				page: {
					title,
					url,
				},
			});
		},

		trackEvent: (event: string, data: { [key: string]: any }) => {
			trackData({ event, ...data });
		},

		trackData,
	};
};
