import '../styles/style.scss';
import { NextComponentType, NextPageContext } from 'next';
import { useRouter } from 'next/router';
import React from 'react';
import { ParsedUrlQuery } from 'querystring';
import { EventTrackerProvider } from '../components/analytics/EventTrackerProvider';
import { createGtmTracker } from '../components/analytics/createGtmTracker';
import { createConsoleTracker } from '../components/analytics/createConsoleTracker';
import * as Sentry from '@sentry/node';

const trackers = [createGtmTracker(), process.env.NODE_ENV !== 'production' ? createConsoleTracker() : null].filter(
	(val): val is NonNullable<typeof val> => val != null
);

type Url = {
	pathname: string;
	query: ParsedUrlQuery;
	asPath: string;
};

type Props = {
	Component: NextComponentType<NextPageContext, {}, { url: Url }>;
	pageProps: {};
	err: any;
};

Sentry.init({
	enabled: process.env.NODE_ENV === 'production',
	dsn: process.env.SENTRY_DSN,
});

const App = ({ Component, pageProps, err }: Props) => {
	const router = useRouter();

	return (
		<EventTrackerProvider trackers={trackers}>
			<Component
				url={{
					pathname: router.pathname,
					query: router.query,
					asPath: router.asPath,
				}}
				{...pageProps}
				// Workaround for https://github.com/vercel/next.js/issues/8592
				// @ts-ignore
				err={err}
			/>
		</EventTrackerProvider>
	);
};

export default App;
